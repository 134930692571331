import { useSelector } from "react-redux";

import SntKeyIcon from "@/components/Icons/SntKeyIcon";

const NoAccessPage = () => {
  const language = useSelector((state) => state.language);

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
        textAlign: "center",
      }}
    >
      <div>
        <SntKeyIcon size={48} />
      </div>
      <h2 className="mb-4" style={{ maxWidth: "600px", width: "100%" }}>
        {language.access_denied_key}
      </h2>
      <div
        style={{
          maxWidth: "600px",
          width: "100%",
          wordWrap: "break-word",
        }}
      >
        <span
          dangerouslySetInnerHTML={{ __html: language.no_access_content_key }}
        />
      </div>
    </div>
  );
};

export default NoAccessPage;
