import SntIcon from "./SntIcon";

const SntKeyIcon = (props) => {
  return (
    <SntIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79419 6.06331C7.79419 6.11139 7.79339 6.15927 7.7918 6.20694L15.3416 10.7518L16 13.3136L13.543 14L12.2449 13.2186L12.0656 11.667L10.6872 12.2809L10.5079 10.7293L9.12954 11.3432L6.02004 9.47137C5.40927 9.88583 4.68015 10.1266 3.8971 10.1266C1.74479 10.1266 0 8.30742 0 6.06331C0 3.81921 1.74479 2 3.8971 2C6.0494 2 7.79419 3.81921 7.79419 6.06331ZM2.69799 5.75075C3.19468 5.75075 3.59732 5.33093 3.59732 4.81306C3.59732 4.29519 3.19468 3.87538 2.69799 3.87538C2.2013 3.87538 1.79866 4.29519 1.79866 4.81306C1.79866 5.33093 2.2013 5.75075 2.69799 5.75075Z"
      />
    </SntIcon>
  );
};

export default SntKeyIcon;
